import * as olStyles from 'ol/style';
import * as olControls from 'ol/control';
import * as olProj from 'ol/proj';
import * as olLayer from 'ol/layer';
import * as olSource from 'ol/source';
import * as olTilegrid from 'ol/tilegrid';
import * as olInteractions from 'ol/interaction';
import * as olEvents from 'ol/events';
import * as olFormat from 'ol/format';
import * as olExtent from 'ol/extent';
import * as olGeom from 'ol/geom';
import Feature from 'ol/Feature';
import * as olOverlay from 'ol/Overlay';
import { fromExtent } from 'ol/geom/Polygon';

export const ol = {
    layer: olLayer,
    source: olSource,
    tilegrid: olTilegrid,
    proj: olProj,
    control: olControls,
    style: olStyles,
    interaction: olInteractions,
    events: olEvents,
    format: olFormat,
    extent: olExtent,
    geom: olGeom,
    Feature: Feature,
    PolygonFromExtent: fromExtent
}